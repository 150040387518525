import React, { useEffect } from "react";
import { navigate, PageProps } from "gatsby";
import {
  Theme,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components";
import { Route } from "@interfaces";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useAuth } from "@hooks";
import SuccessfulPayment from "@components/Services/PaymentStatus/PaymentStatus";

import {
  SUCCESSFUL_PAY_ASTRONAUT,
} from "images/GoogleCloud";
import { getUserSubscriptions, updateUserSubscription } from "@apollo";
import { useApolloClient } from "@apollo/client";

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const client = useApolloClient();
  const isMobile = useMediaQuery("(max-width: 1270px)");
  const { userSubscriptions } = getUserSubscriptions()


  useEffect(() => {
    const planId = Number(window.location.href.split("#")[1])

    const unpaidSubscription = userSubscriptions.find((subs) => subs.planId.id === planId)
    
    const dataToSend = {
      id: unpaidSubscription?.id,
      paid: true
    }

    updateUserSubscription(client, dataToSend)
  }, [userSubscriptions])

  return (
    <>
      {isMobile ? (
        <LayoutMobileInvestments activeServices>
          <>
            <SuccessfulPayment
              image={SUCCESSFUL_PAY_ASTRONAUT}
              titleText={"¡Felicidades!"}
              descriptionText={
                "Has contratado tu plan. Te enviaremos las instrucciones para activarlo y comenzar a disfrutarlo a este correo:"
              }
              backgroundColorCode={"#0A5669"}
              backRouteFunction={() => navigate(Route.services)}
              showMail={true}
            />
          </>
        </LayoutMobileInvestments>
      ) : (
        <>
          <SEO />
          <LayoutOnBoarding>
            <>
              <SuccessfulPayment
                image={SUCCESSFUL_PAY_ASTRONAUT}
                titleText={"¡Felicidades!"}
                descriptionText={
                  "Has contratado tu plan. Te enviaremos las instrucciones para activarlo y comenzar a disfrutarlo a este correo:"
                }
                backgroundColorCode={"#0A5669"}
                backRouteFunction={() => navigate(Route.services)}
                showMail={true}
              />
            </>
          </LayoutOnBoarding>
        </>
      )}
    </>
  );
};

export default SaveByRokinPage;